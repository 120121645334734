import React from 'react';
import logo from './logo.svg';

function App() {
  return (
    <div  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <header>
        <img style={{height: 100}} src={logo} alt="logo" />
      </header>
    </div>
  );
}

export default App;
